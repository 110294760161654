import { useEffect, useState } from 'react'
import { useStyles } from './styles';
import { SearchBar } from '@app/components/widgets/searchBar';
import { Label } from '@app/components/elements/Label';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { routeSelector } from '@app/router/redux';
import { dcrTransferPromotionSelector, dcrTransferPromotionStateActions, fetchPositionTransferRequestListCreator } from '@app/screens/module-specific-utilities/pages/dcr-transfer-promotion/redux';



export const PositionTransferNavbar = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [search, setSearch] = useState('');
    const [searchOptionSelected, setSearchOptionSelected] = useState(2)
    const [searchError, setSearchError] = useState(false);
    const [showSuggestion, setShowSuggestion] = useState(false);
    const [suggestionList, setSuggestionList] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');

    const navbarComponentHeading = useSelector(
        routeSelector.getNavbarComponentHeading(),
    );
    const employeeDetails = useSelector(dcrTransferPromotionSelector.getEmployeesList());
    const gridOptions = useSelector(dcrTransferPromotionSelector.getPositionTransferRequestGridOptionSelector());


    const handleSearchPress = (e: any) => {
        if (e.key === 'Enter') {
            if (search?.length >= 3 || search?.length === 0) {
                setSearchError(false);
                fetchListData();
            } else {
                setSearchError(true);
                setErrorMsg('Min 3 characters')
            }
        }
    };
    const fetchListData = () => {
        dispatch(dcrTransferPromotionStateActions.setPositionTransferRequestGridOptions({
            ...gridOptions,
            pageNo: 0,
        }));
        dispatch(fetchPositionTransferRequestListCreator());
    }
    useEffect(() => {
        dispatch(dcrTransferPromotionStateActions.setEmployeeList(null));
    }, []);

    useEffect(() => {
        if (employeeDetails) {

            if (employeeDetails?.details?.length > 1) {
                setShowSuggestion(true);
                setSuggestionList(employeeDetails?.details);
                return;
            } else if (employeeDetails?.details?.staffPositionId) {
                const { empid, empName, staffPositionId, wsfaPositionCode } = employeeDetails?.details;
                let searchPlaceholder = empid ? `${empid} | ` : '';
                searchPlaceholder = empName ? `${searchPlaceholder} ${empName} |` : searchPlaceholder;
                searchPlaceholder = staffPositionId ? `${searchPlaceholder} ${staffPositionId} |` : searchPlaceholder;
                searchPlaceholder = wsfaPositionCode ? `${searchPlaceholder} ${wsfaPositionCode}` : searchPlaceholder;
                setSearch(searchPlaceholder);

            } else if (employeeDetails?.details?.length === 0) {
                setSearchError(true)
                setErrorMsg(`Employee not found`);

            }
        }
    }, [employeeDetails]);

    const handleSuggestionSelected = (value: any) => {

        setShowSuggestion(false);
        dispatch(dcrTransferPromotionStateActions.setEmployeeList({ details: value }));
    }

    const handleSearchTextChange = (value: any) => {
        setSearch(value);
        dispatch(dcrTransferPromotionStateActions.setPositionTransferRequestSearchText(value));

        if (!value?.length || value?.length >= 3) {
            setSearchError(false);
        }
    };

    const handleClearClick = () => {

        setSearch('')
        setSearchError(false);
        setErrorMsg('');
        setShowSuggestion(false)
        dispatch(dcrTransferPromotionStateActions.setEmployeeList(null));
    }


    return (
        <div className={classes.container}>
            <div className={classes.flexSpaceBetween}>
                <Label
                    title={navbarComponentHeading}
                    variant="h6"
                    testID={'superman_admin_navbar_heading'}
                    textColor={'#322b7c'}
                    fontWeight={'800'}
                    hideToolTip
                />
            </div>
            <div className={classes.searchContainer}>
                <SearchBar
                    searchText={search}
                    handleChange={handleSearchTextChange}
                    handleSearch={handleSearchPress}
                    showError={searchError}
                    placeHolder={'Search with SAPCode/Employee Name'}
                    errorMessage={errorMsg}
                    handleClear={() => handleClearClick()}
                    showSuggestion={showSuggestion}
                    suggestionList={suggestionList}
                    suggestionSelected={(e: any) => handleSuggestionSelected(e)}
                />
            </div>
        </div>
    )
}
